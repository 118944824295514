<template>
  <div>
    <a-row :gutter="24" type="flex">

      <!-- Projects Table Column -->
      <a-col :span="24" class="mb-24">

        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
          <template #title>
            <a-row type="flex" align="middle">
              <a-col :span="24" :md="12" class="top-left-header">
                <h5 class="font-semibold m-0">Revenue Report<a-spin v-if="confirmLoading"/></h5>
              </a-col>
              <a-col :span="24" :md="12" class="top-right-header">
                <a-button>
                  <export-excel
                      :data="jsonRevenueReportData"
                      :fields="jsonFields"
                      worksheet="My Worksheet"
                      :name="'revenue-report.xls'">
                    Export
                  </export-excel>
                </a-button>
                <a-range-picker v-model:dateRange="dateRange" format="YYYY-MM-DD" @change="onDateChange" :disabled-date="disabledDate"/>
                <a-radio-group size="small">
                </a-radio-group>
              </a-col>
            </a-row>
          </template>
          <a-table :columns="columnData"
                   :data-source="revenueReportList"
                   :pagination="false"
                   :scroll="{x: true}">

            <template slot="name" slot-scope="text">
              <a>{{ text }}</a>
            </template>
          </a-table>
        </a-card>

      </a-col>
      <!-- / Projects Table Column -->

    </a-row>
  </div>
</template>

<script>
import moment from "moment/moment"
import {sendRequest} from '@/http/axios.method';

const COLUMN_DATA = [
  {
    title: 'Time',
    dataIndex: 'time',
    class: 'font-semibold text-muted',
    width: 130,
  },
  {
    title: 'Service name',
    dataIndex: 'service_name',
  },
  {
    title: 'Total Revenue',
    dataIndex: 'total_revenue',
    class: 'font-semibold text-muted',
  },
  {
    title: 'Total Subscription Revenue',
    dataIndex: 'total_subscription_revenue',
    class: 'font-semibold text-muted',
  },
  {
    title: 'Total Active Subscriber',
    dataIndex: 'total_active_subscriber',
    class: 'font-semibold text-muted',
  },
  {
    title: 'New Subscription Attempt',
    dataIndex: 'new_subscription_attempt',
    class: 'font-semibold text-muted',
  },
  {
    title: 'New Subscription Revenue',
    dataIndex: 'new_subscription_revenue',
    class: 'font-semibold text-muted',
  },
  {
    title: 'New Subscription Count',
    dataIndex: 'new_subscription_count',
    class: 'font-semibold text-muted',
  },
  {
    title: 'Re-Order Attempt',
    dataIndex: 're_order_attempt',
    class: 'font-semibold text-muted',
  },
  {
    title: 'Re-Order Revenue',
    dataIndex: 're_order_revenue',
    class: 'font-semibold text-muted',
  },
  {
    title: 'Re-Order Count',
    dataIndex: 're_order_count',
    class: 'font-semibold text-muted',
  },
  {
    title: 'On-Demanded Count',
    dataIndex: 'on_demanded_count',
    class: 'font-semibold text-muted',
  },
  {
    title: 'On-Demanded Revenue',
    dataIndex: 'on_demanded_revenue',
    class: 'font-semibold text-muted',
  },
  {
    title: 'New subscription count bdyw new',
    dataIndex: 'new_subscription_count_bdyw_new',
    class: 'font-semibold text-muted',
  },
  {
    title: 'Re order attempt bdyw new',
    dataIndex: 're_order_attempt_bdyw_new',
    class: 'font-semibold text-muted',
  },
  {
    title: 'Re order revenue bdyw new',
    dataIndex: 're_order_revenue_bdyw_new',
    class: 'font-semibold text-muted',
  },
  {
    title: 'Re order count bdyw new',
    dataIndex: 're_order_count_bdyw_new',
    class: 'font-semibold text-muted',
  },
];

const JSON_FIELD = {
  'Time': 'time',
  'Service name': 'service_name',
  'Total Revenue': 'total_revenue',
  'Total Subscription Revenue': 'total_subscription_revenue',
  'Total Active Subscriber': 'total_active_subscriber',
  'New Subscription Attempt': 'new_subscription_attempt',
  'New Subscription Revenue': 'new_subscription_revenue',
  'New Subscription count': 'new_subscription_count',
  'Re-order Attempt': 're_order_attempt',
  'Re-order Revenue': 're_order_revenue',
  'Re-order count': 're_order_count',
  'On-Demanded Count': 'on_demanded_count',
  'On-Demanded Revenue': 'on_demanded_revenue',
  'New subscription_revenue_bdyw_new': 'new_subscription_revenue_bdyw_new',
  'New subscription_count_bdyw_new': 'new_subscription_count_bdyw_new',
  'Re order_attempt_bdyw_new': 're_order_attempt_bdyw_new',
  'Re order_revenue_bdyw_new': 're_order_revenue_bdyw_new',
  'Re order_count_bdyw_new': 're_order_count_bdyw_new',
}

const JSON_META = [
  [
    {
      'key': 'charset',
      'value': 'utf-8'
    }
  ]
]
export default {
  name: "RevenueReport",
  data() {
    return {
      config: {
        'Authorization': 'Bearer ' + this.$store.state.authUser.token
      },
      columnData: COLUMN_DATA,
      revenueReportData: [],
      revenueReports: [],
      startDate: '',
      endDate: '',
      dateRange: [],
      jsonFields: JSON_FIELD,
      jsonRevenueReportData: [],
      json_meta: JSON_META,
      confirmLoading: false,
    }
  },
  created() {
    this.startDate = this.endDate = moment().add(-1, 'days').format('YYYY-MM-DD')
    this.getRevenueReport()
  },
  methods: {
    disabledDate(event) {
      return moment().isSameOrBefore(moment(event));
    },
    onDateChange() {
      this.startDate = '';
      this.endDate = '';
      this.revenueReports = [];
      this.revenueReportData = [];

      if (Object.keys(this.dateRange).length) {
        this.startDate = moment(this.dateRange[0]).format('YYYY-MM-DD')
        this.endDate = moment(this.dateRange[1]).format('YYYY-MM-DD')
      }

      if (this.startDate.length && this.endDate.length) {
        this.getRevenueReport()
      }
    },
    getRevenueReport() {
      let data = {
        date_range: {
          start: this.startDate,
          end: this.endDate
        }
      }
      this.confirmLoading = true
      sendRequest('post', 'admin/revenue-report', data , this.config)
          .then(response => {
            this.confirmLoading = false
            if (response.success) {
              this.revenueReports = response.data
              this.revenueReportData = this.revenueReports.map(function (revenue, label) {
                return {
                  key: label + 1,
                  time: revenue.time,
                  service_name: revenue.service_name,
                  total_revenue: revenue.total_revenue,
                  total_subscription_revenue: revenue.total_subscription_revenue,
                  total_active_subscriber: revenue.total_active_subscriber,
                  new_subscription_attempt: revenue.new_subscription_attempt,
                  new_subscription_revenue: revenue.new_subscription_revenue,
                  new_subscription_count: revenue.new_subscription_count,
                  re_order_attempt: revenue.re_order_attempt,
                  re_order_revenue: revenue.re_order_revenue,
                  re_order_count: revenue.re_order_count,
                  on_demanded_count: revenue.on_demanded_count,
                  on_demanded_revenue: revenue.on_demanded_revenue,
                  new_subscription_revenue_bdyw_new: revenue.new_subscription_revenue_bdyw_new,
                  new_subscription_count_bdyw_new: revenue.new_subscription_count_bdyw_new,
                  re_order_attempt_bdyw_new: revenue.re_order_attempt_bdyw_new,
                  re_order_revenue_bdyw_new: revenue.re_order_revenue_bdyw_new,
                  re_order_count_bdyw_new: revenue.re_order_count_bdyw_new,
                };
              });
            } else {
              if (response.status_code === 401) {
                this.$store.dispatch('logout');
                this.$router.push('/login');
              }
              if (response.status_code === 500) {
                this.revenueReportData = [];
              }
            }
          })
          .catch(() => {
            this.confirmLoading = false
          })
          .finally(() => {
            this.confirmLoading = false
          })
    },

    loadRevenueData(revenue) {
      this.jsonRevenueReportData.push({
        time: revenue.time,
        service_name: revenue.service_name,
        total_revenue: revenue.total_revenue,
        total_subscription_revenue: revenue.total_subscription_revenue,
        total_active_subscriber: revenue.total_active_subscriber,
        new_subscription_attempt: revenue.new_subscription_attempt,
        new_subscription_revenue: revenue.new_subscription_revenue,
        new_subscription_count: revenue.new_subscription_count,
        re_order_attempt: revenue.re_order_attempt,
        re_order_revenue: revenue.re_order_revenue,
        re_order_count: revenue.re_order_count,
        on_demanded_count: revenue.on_demanded_count,
        on_demanded_revenue: revenue.on_demanded_revenue,
        new_subscription_revenue_bdyw_new: revenue.new_subscription_revenue_bdyw_new,
        new_subscription_count_bdyw_new: revenue.new_subscription_count_bdyw_new,
        re_order_attempt_bdyw_new: revenue.re_order_attempt_bdyw_new,
        re_order_revenue_bdyw_new: revenue.re_order_revenue_bdyw_new,
        re_order_count_bdyw_new: revenue.re_order_count_bdyw_new,
      })
    }
  },
  computed: {
    revenueReportList: function () {
      this.jsonRevenueReportData = [];
      this.revenueReports.map( revenue => {
        this.loadRevenueData(revenue)
      });
      return this.revenueReportData;
    }
  }
}
</script>

<style scoped>

</style>
